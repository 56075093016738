<template>
	<div class="policyCompilation">
		<div class="policyCompilationTitle">
			<p>找项目：科技企业集聚追踪系统(精准招商)</p>
			<p style="font-size: 16px;">企业追踪【招商OA】-- {{name}}项目跟进日志</p>
		</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/enterpriseTracking' }">
				<el-button type="primary" size="mini">返回</el-button>
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				<el-button type="text" size="mini" style="color: #555;font-size: 14px;">跟进日志</el-button>
			</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="top"><el-button type="primary" size="mini" @click="writeLog">写日志</el-button></div>
		<el-table :data="tableData" border style="width: 100%;">
			<el-table-column label="创建时间" align="center" prop="created_at" width="160"></el-table-column>
			<el-table-column label="项目阶段" align="center" prop="oa_stage"></el-table-column>
			<el-table-column label="跟进人" align="center" prop="manager"></el-table-column>
			<el-table-column label="跟进内容" align="left" header-align="center" prop="content" width="300">
				<template v-slot="scope">
					<p v-html="scope.row.content"></p>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" width="240">
				<template slot-scope="scope">
					<el-button icon="el-icon-edit" type="primary" size="mini" @click="editLog(scope.row)">编辑</el-button>
					<el-button icon="el-icon-delete" size="mini" @click="delLog(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pages">
			<el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-count="pageCount" layout="total,prev,pager,next,jumper"
			 :total="total">
			</el-pagination>
		</div>
		<el-dialog :title="title" :visible.sync="writeActive">
			<Editor @getContent="getEditorContent" :value="content"></Editor>
			<span slot="footer" class="dialog-footer">
				<el-button @click="writeActive = false">取 消</el-button>
				<el-button type="primary" @click="editJournal">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import Editor from '@/components/Editor';
export default {
	data() {
		return {
			project_id: this.$route.query.project_id,
			name:this.$route.query.name,
			organ_id: sessionStorage.organizationId,
			writeActive: false,
			title: '写日志',
			content: '',
			tableData: [],
			logId: '',
			currentPage: 1,
			pageCount: Math.ceil(this.total / 10),
			total: 0,
			page: 1,
			limit: 10,
		};
	},
	components: {
		Editor
	},
	mounted() {
		this.getJournal();
	},
	methods: {
		handleCurrentChange(val) {
			this.page = val;
			this.getJournal();
		},
		/* 获取日志列表  */
		getJournal() {
			this.$http.get(this.URL.adminUrl.journal,{
				params:{
					page:this.page,
					limit:this.limit,
					project_id:this.project_id,
					organ_id:this.organ_id
				}
			}).then(res => {
				this.tableData = res.data.data.data;
				this.total = res.data.data.meta.total
			});
		},
		/* 拿到富文本内容  */
		getEditorContent(data) {
			this.content = data;
		},
		/* 写日志 */
		writeLog() {
			this.$http.get(this.URL.adminUrl.jurisdiction,{
				params:{
					project_id:this.project_id,
					organ_id:this.organ_id
				}
			}).then(res=>{
				if(res.data.data){
					this.writeActive = true;
					this.title = '写日志';
					this.content = '';
				}else{
					this.$message.error('你没有写日志的权限，请联系超管。')
				}
			})
		},
		/* 编辑日志 */
		editLog(row) {
			this.writeActive = true;
			this.title = '编辑日志';
			this.content = row.content;
			this.logId = row.id;
		},
		/* 提交日志 */
		editJournal() {
			if (this.title == '编辑日志') {
				this.$http
					.put(this.URL.adminUrl.editJournal + this.logId, {
						content: this.content,
						project_id:this.project_id,
						organ_id:this.organ_id
					})
					.then(res => {
						this.getJournal();
					})
					.catch(err => {
						this.$message.error(err.response.data.message);
					});
			} else {
				this.$http
					.post(this.URL.adminUrl.journal, {
						project_id: this.project_id,
						content: this.content,
						organ_id: this.organ_id
					})
					.then(res => {
						this.getJournal();
					})
					.catch(err => {
						this.$message.error(err.response.data.message);
					});
			}
			this.writeActive = false;
		},
		/* 删除日志 */
		delLog(row) {
			this.$confirm('确认删除', '提示', {
			})
				.then(res => {
					this.$http
						.delete(this.URL.adminUrl.editJournal + row.id)
						.then(res => {
							this.getJournal();
						})
						.catch(err => {
							this.$message.error(err.response.data.message);
						});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		}
	}
};
</script>

<style scoped>
.policyCompilationTitle {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	font-size: 18px;
	height: 60px;
}

.navbar {
	margin-bottom: 30px;
	padding: 15px;
	border-bottom: 1px solid #ccc;
}

.top {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
</style>
